import { AppType } from '../../../API/application';


const appTypeNames: Record<AppType, string> = {
    [AppType.regular_web]: 'Regular Web Application',
    [AppType.spa]: 'Single Page Application',
    [AppType.native]: 'Native',
    [AppType.non_interactive]: 'Machine to Machine',
};

export const getAppTypeName = (app_type: AppType): string => {
    return appTypeNames[app_type] ?? 'Unknown Type';
};