import colors from '@danfoss/webex-ui/dist/styles/_colors.scss';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
	toggleButtonContainer: {
		marginBottom: '20px',
		justifyContent: 'end'
	},
	applicationListGridHeading: {
		backgroundColor: colors.webexuiGraySoft,
		minHeight: '40px',
		fontWeight: 600
	},
	applicationListGridItem: {
		padding: '5px 8px 5px 8px',
		marginTop: '5px'
	},
	applicationListGridEdit: {
		width: '15%',
		[theme.breakpoints.down('md')]: {
			minWidth: '140px'
		}
	},
	applicationListGridEdit2: {
		width: '10%',
		[theme.breakpoints.down('md')]: {
			minWidth: '80px'
		}
	},
	applicationListGridName: {
		width: '40%'
	},
	applicationListGridType: {
		width: '20%',
	},
	applicationListDetails: {
		borderWidth: '1px',
		borderStyle: 'solid',
		borderColor: 'lightgrey',
		borderRadius: '10px',
		margin: '10px 10px',
		backgroundColor: 'white',
		width: 'calc(100% - 20px)',
		padding: '15px 0px'

	},
	applicationListSectionStart: {
		padding: '0px 25px 0px 25px'
	},
	applicationListSectionDivider: {
		margin: '15px 25px 15px 25px',
		borderWidth: '1px',
		borderStyle: 'solid',
		borderColor: 'lightgrey',
	},
	applicationListSectionContinue: {
		padding: '10px 25px 0px 25px'
	},
	applicationListSectionHeadingColumn: {
		fontWeight: 600,
		width: '40%'
	},
	applicationListSectionPropertiesColumn: {
		width: '60%'
	},
	applicationListGridProperty: {
		width: '100%',
		fontWeight: 600,
	},
	applicationListGridValue: {
		width: '100%',
	},
	iconStyle: {
		top: '0',
		right: '0',
		display: 'inline-block',
		color: '#c0c0c0',
		width: '13px',
		height: '13px',
		margin: '12px 10px 12px 0px',
		verticalAlign: 'middle',
		pointerEvents: 'none'
	},
	expandText: {
		verticalAlign: 'middle'
	},
	iconStyleRotate: {
		transform: 'rotate(180deg)'
	},
	criteriaIcon: {
		cursor: 'pointer'
	},
	applicationListGridAttribute: {
		width: '30%',
		[theme.breakpoints.down('md')]: {
			width: '35%',
		}
	},
	applicationListGridAndOr: {
		width: '10%',
		minWidth: '85px'
	},
	applicationListGridValues: {
		width: '30%',
		[theme.breakpoints.down('md')]: {
			width: '35%',
		}
	},
	applicationListGridCritEdit: {
		width: '65%',
		[theme.breakpoints.down('md')]: {
			width: '60%',
		}
	},
	applicationListGridStatus: {
		width: '15%',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
	},
	applicationListGridAddCrit: {
		paddingLeft: '0px'
	},
	applicationListGrid: {
		minHeight: '40px'
	},
	critListGrid: {
		marginTop: '-5px',
		marginLeft: '-10px',
		minHeight: '30px'
	},
	applicationListGridCopyFrom: {
		borderColor: 'red',
		borderWidth: '2px',
		borderRadius: '4px',
		borderStyle: 'dotted'
	},
	applicationListGridOdd: {
		backgroundColor: colors.webexuiGraySoft
	},
	applicationListGridEven: {
		backgroundColor: colors.webexuiWhite
	},
	editGridButton: {
		marginBottom: '12px',
		height: '30px',
		minWidth: '160px',
		[theme.breakpoints.down('md')]: {
			minWidth: '130px',
			padding: '7px 7px 7px 7px'
		}
	},
	editOwnerGridButton: {
		marginTop: '12px',
		height: '30px'
	},
	button: {
		marginRight: '20px'
	},
	bold: {
		fontWeight: 'bold',
	},
	normal: {
		fontWeight: 'normal',
	},
}));