import { OwnerInfo } from '../../../API/application';
import { ApplicationErrors, ApplicationWarnings } from '../../ImportPopup/types';
import { validEmail, isValidEmailDomain } from '../validation'


export const validateImportedData = (applicationName: string, idpDomains: string[], importedData: OwnerInfo[]): { errors: ApplicationErrors[], warnings: ApplicationWarnings[] } => {
    const errors: string[] = [];
    const warnings: string[] = [];

    importedData.forEach(role => {
        if (!validEmail(role.email)) {
            errors.push(`There is a user with invalid email: ${role.email}`);
        } else if (!isValidEmailDomain(role.email, idpDomains)) {
            errors.push(`Owners must be internal Danfoss users. You cannot use: ${role.email}`);
        }

        if (!role.danfoss_identity_id) {
            errors.push('There is a user without ID');
        }
    })

    return {
        errors: errors.length > 0 ? [{
            client_name: applicationName,
            errors: errors,
        }] : [],
        warnings: warnings.length > 0 ? [{
            client_name: applicationName,
            warnings: warnings,
        }] : [],
    };
}
