import React, { useEffect, useState } from 'react';
import Main from '../../Main/Main';
import Grid from '@material-ui/core/Grid';
import { useStyles } from './styles';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState } from '../../../Store';
import settings from '../../../Config/settings';
import { useAuth0 } from '@auth0/auth0-react';
import { ApplicationSetup, getApplicationsSetup } from '../../../API/application';
import DataLoader from '../../DataLoader';
import { getAppTypeName } from './utils';
import { Spinner } from '@danfoss/webex-ui';
import { RenderApplicationUrls, RenderBasicInformation, RenderClientMetaData, RenderConnections, RenderEndpoints, RenderGrantTypes, RenderIdToken, RenderRefreshToken } from './SubSections'
import { ApiAccessInfo } from './ApiAccessInfo';

const AppSetup: React.FC = () => {
	const classes = useStyles();
	const location = useLocation();
	const { getAccessTokenSilently } = useAuth0();
	const [applicationsSetup, setApplicationsSetup] = useState<ApplicationSetup[]>([]);
	const [expandedApplication, setExpandedApplication] = useState('');
	const [loading, setLoading] = useState(false);
	const applications = useSelector((state: AppState) => state.applications);
	const applicationsLoaded = useSelector((state: AppState) => state.applications.applicationsLoadingStatus.loaded);
	const currentUser = useSelector((state: AppState) => state.user);

	const toggleShowDetails = (client_id: string) => {
		if (expandedApplication === client_id) {
			setExpandedApplication('');
		}
		else {
			setExpandedApplication(client_id);
		}
	}

	useEffect(() => {
		const loadRecommendedApps = async () => {
			if (currentUser.userLoaded && currentUser.user && applicationsLoaded) {
				setLoading(true);
				const accessTokenMyDanfossApi = await getAccessTokenSilently(settings.myDanfossApi.accessTokenOptions);
				const appsSetup = await getApplicationsSetup(accessTokenMyDanfossApi);

				setApplicationsSetup(appsSetup);
				setLoading(false);
			}
		}
		loadRecommendedApps();

	}, [applications.applications, applicationsLoaded, currentUser]);


	return (
		<Main breadCrumbs={{ items: [{ text: `Application setup`, link: location.pathname }] }}>
			<DataLoader applicationsRequired={true} >
				<Spinner visible={loading} ></Spinner>
				<Grid container className={classes.applicationListGridHeading}>
					<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridName}`} >
						Application name
					</Grid>
					<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridType}`} >
						Type
					</Grid>
					<Grid item className={classes.criteriaIcon} >
					</Grid>
				</Grid>
				{applicationsSetup
					.sort((app1, app2) => app1.name.localeCompare(app2.name))
					.map((app, rowNo) => {
						const rowClass = rowNo % 2 === 0 ? classes.applicationListGridEven : classes.applicationListGridOdd;
						return (
							<React.Fragment key={rowNo}>
								<Grid container className={`${classes.applicationListGrid} ${rowClass}`}>
									<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridName}`} >
										{app.name}
									</Grid>
									<Grid item className={`${classes.applicationListGridItem} ${classes.applicationListGridType}`} >
										{getAppTypeName(app.app_type)}
									</Grid>
									<Grid item className={classes.criteriaIcon} onClick={() => { toggleShowDetails(app.client_id) }} >
										<img src={'/images/icons/ChevronDown.svg'} className={expandedApplication === app.client_id ? `${classes.iconStyleRotate} ${classes.iconStyle} ` : classes.iconStyle} alt="" />
										<span className={classes.expandText}>{expandedApplication === app.client_id ? 'Hide' : 'Show'} details</span>
									</Grid>
								</Grid>
								{expandedApplication === app.client_id &&
									<div className={classes.applicationListDetails}>
										<RenderBasicInformation classes={classes} application={app} />
										<RenderApplicationUrls classes={classes} application={app} />
										<RenderClientMetaData classes={classes} application={app} />
										<RenderIdToken classes={classes} application={app} />
										<RenderRefreshToken classes={classes} application={app} />
										<RenderConnections classes={classes} application={app} />
										<RenderGrantTypes classes={classes} application={app} />
										<RenderEndpoints classes={classes} application={app} />
										<ApiAccessInfo classes={classes} application={app} />
									</div>
								}
							</React.Fragment>
						)
					})}

			</DataLoader>
		</Main>
	);
}

export default AppSetup;


