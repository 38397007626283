import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme, { disableScroll?: boolean }>(theme => ({
	confirm: {
		boxShadow: '0px -4px 0px #E2000F',
		borderRadius: '0px 0px 2px 2px',
		"&:hover": {
			boxShadow: '0px -4px 0px #E2000F, 0px 12px 17px 2px rgba(66, 78, 84, 0.14)'
		}
	},
	confirmButtons: {
		marginTop: '20px'
	},
	confirmPopup: {
		position: 'absolute',
		margin: '0 auto',
		maxWidth: '1000px',
		[theme.breakpoints.up('sm')]: {
			margin: '50px 15px 0 !important'
		},
		textAlign: 'left',
		paddingBottom: '23px',
		padding: '16px 35px 20px 35px !important'
	},
	confirmPopupContainer: {
		maxWidth: '1280px',
		display: 'flex',
		justifyContent: 'center',
		position: ({ disableScroll }) => disableScroll ? 'fixed' : 'absolute',
		top: ({ disableScroll }) => disableScroll ? 100 : 0,
		zIndex: ({ disableScroll }) => disableScroll ? 1 : 'initial',
	},
	confirmPopupHeader: {
		fontSize: '24px',
		fontWeight: 'bold',
		margin: '20px 0 16px'
	},
	bannerContainer: {
		position: 'relative'
	},
	errorList: {
		overflow: 'auto',
		maxHeight: 'calc(100vh - 360px)'
	},
	button: {
		marginRight: '20px'
	},
}));