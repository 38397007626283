import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    dateColumn: {
        width: '200px',
    },
    code: {
        opacity: 0.5,
        fontSize: 14,
    },
    paginationButton: {
        marginTop: theme.spacing(0.5)
    }
}));