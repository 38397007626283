import React from 'react';
import Grid from '@material-ui/core/Grid';
import settings from '../../../Config/settings';
import { SubSectionProps } from './types'
import { AppType } from '../../../API/application';

const listItems = (items: string[] | undefined) => {
    return (items && items.length > 0) ? items.map((cb, key) => { return <div key={key}>{cb}</div> }) : 'N/A';
}


export const RenderBasicInformation: React.FC<SubSectionProps> = ({ classes, application }) => {
    return (
        <React.Fragment>
            <Grid container className={`${classes.applicationListSectionStart}`}>
                <Grid item className={`${classes.applicationListSectionHeadingColumn}`} >
                    Basic Information
                </Grid>
                <Grid item className={`${classes.applicationListSectionPropertiesColumn}`} >
                    <Grid item className={`${classes.applicationListGridProperty}`} >
                        Name
                    </Grid>
                    <Grid item className={`${classes.applicationListGridValue}`} >
                        {application.name}
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className={`${classes.applicationListSectionContinue}`}>
                <Grid item className={`${classes.applicationListSectionHeadingColumn}`} >
                </Grid>
                <Grid item className={`${classes.applicationListSectionPropertiesColumn}`} >
                    <Grid item className={`${classes.applicationListGridProperty}`} >
                        Domain
                    </Grid>
                    <Grid item className={`${classes.applicationListGridValue}`} >
                        {settings.auth0.custom_domain}
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className={`${classes.applicationListSectionContinue}`}>
                <Grid item className={`${classes.applicationListSectionHeadingColumn}`} >
                </Grid>
                <Grid item className={`${classes.applicationListSectionPropertiesColumn}`} >
                    <Grid item className={`${classes.applicationListGridProperty}`} >
                        Client ID
                    </Grid>
                    <Grid item className={`${classes.applicationListGridValue}`} >
                        {application.client_id}
                    </Grid>
                </Grid>
            </Grid>
            <hr className={classes.applicationListSectionDivider}></hr>
        </React.Fragment>
    )
}

export const RenderApplicationUrls: React.FC<SubSectionProps> = ({
    classes,
    application: { initiate_login_uri, callbacks, allowed_logout_urls, web_origins, allowed_origins },
}) => {
    if (!initiate_login_uri && !callbacks && !allowed_logout_urls && !web_origins && !allowed_origins) {
        return null;
    }

    return (
        <React.Fragment>
            <Grid container className={`${classes.applicationListSectionStart}`}>
                <Grid item className={`${classes.applicationListSectionHeadingColumn}`} >
                    Application URIs
                </Grid>
                <Grid item className={`${classes.applicationListSectionPropertiesColumn}`} >
                    <Grid item className={`${classes.applicationListGridProperty}`} >
                        Application Login URI
                    </Grid>
                    <Grid item className={`${classes.applicationListGridValue}`} >
                        {initiate_login_uri || 'N/A'}
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className={`${classes.applicationListSectionContinue}`}>
                <Grid item className={`${classes.applicationListSectionHeadingColumn}`} >
                </Grid>
                <Grid item className={`${classes.applicationListSectionPropertiesColumn}`} >
                    <Grid item className={`${classes.applicationListGridProperty}`} >
                        Allowed Callback URLs
                    </Grid>
                    <Grid item className={`${classes.applicationListGridValue}`} >
                        {listItems(callbacks)}
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className={`${classes.applicationListSectionContinue}`}>
                <Grid item className={`${classes.applicationListSectionHeadingColumn}`} >
                </Grid>
                <Grid item className={`${classes.applicationListSectionPropertiesColumn}`} >
                    <Grid item className={`${classes.applicationListGridProperty}`} >
                        Allowed Logout URLs
                    </Grid>
                    <Grid item className={`${classes.applicationListGridValue}`} >
                        {listItems(allowed_logout_urls)}
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className={`${classes.applicationListSectionContinue}`}>
                <Grid item className={`${classes.applicationListSectionHeadingColumn}`} >
                </Grid>
                <Grid item className={`${classes.applicationListSectionPropertiesColumn}`} >
                    <Grid item className={`${classes.applicationListGridProperty}`} >
                        Allowed Web Origins
                    </Grid>
                    <Grid item className={`${classes.applicationListGridValue}`} >
                        {listItems(web_origins)}
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className={`${classes.applicationListSectionContinue}`}>
                <Grid item className={`${classes.applicationListSectionHeadingColumn}`} >
                </Grid>
                <Grid item className={`${classes.applicationListSectionPropertiesColumn}`} >
                    <Grid item className={`${classes.applicationListGridProperty}`} >
                        Allowed Origins (CORS)
                    </Grid>
                    <Grid item className={`${classes.applicationListGridValue}`} >
                        {listItems(allowed_origins)}
                    </Grid>
                </Grid>
            </Grid>
            <hr className={classes.applicationListSectionDivider}></hr>
        </React.Fragment>
    )
}

export const RenderIdToken: React.FC<SubSectionProps> = ({ classes, application: { jwt_configuration } }) => {
    if (!jwt_configuration) {
        return null;
    }

    return (
        <React.Fragment>
            <Grid container className={`${classes.applicationListSectionStart}`}>
                <Grid item className={`${classes.applicationListSectionHeadingColumn}`} >
                    ID Token
                </Grid>
                <Grid item className={`${classes.applicationListSectionPropertiesColumn}`} >
                    <Grid item className={`${classes.applicationListGridProperty}`} >
                        ID Token Expiration
                    </Grid>
                    <Grid item className={`${classes.applicationListGridValue}`} >
                        {`${jwt_configuration.lifetime_in_seconds} seconds`}
                    </Grid>
                </Grid>
            </Grid>
            <hr className={classes.applicationListSectionDivider}></hr>
        </React.Fragment>
    )
}

export const RenderRefreshToken: React.FC<SubSectionProps> = ({ classes, application: { refresh_token } }) => {
    if (!refresh_token) {
        return null;
    }

    return (
        <React.Fragment>
            <Grid container className={`${classes.applicationListSectionStart}`}>
                <Grid item className={`${classes.applicationListSectionHeadingColumn}`} >
                    Refresh Token Rotation
                </Grid>
                <Grid item className={`${classes.applicationListSectionPropertiesColumn}`} >
                    <Grid item className={`${classes.applicationListGridProperty}`} >
                        Rotation
                    </Grid>
                    <Grid item className={`${classes.applicationListGridValue}`} >
                        {refresh_token.rotation_type === 'rotating' ? 'Enabled' : 'Disabled'}
                    </Grid>
                </Grid>
                <Grid item className={`${classes.applicationListSectionHeadingColumn}`} >
                </Grid>
                <Grid item className={`${classes.applicationListSectionPropertiesColumn}`} >
                    <Grid item className={`${classes.applicationListGridProperty}`} >
                        Reuse Interval
                    </Grid>
                    <Grid item className={`${classes.applicationListGridValue}`} >
                        {`${refresh_token.leeway || 0} seconds`}
                    </Grid>
                </Grid>
            </Grid>
            {refresh_token.rotation_type === 'rotating' &&
                <React.Fragment>
                    <hr className={classes.applicationListSectionDivider}></hr>
                    <Grid container className={`${classes.applicationListSectionStart}`}>
                        <Grid item className={`${classes.applicationListSectionHeadingColumn}`} >
                            Refresh Token Expiration
                        </Grid>
                        <Grid item className={`${classes.applicationListSectionPropertiesColumn}`} >
                            <Grid item className={`${classes.applicationListGridProperty}`} >
                                Absolute Expiration
                            </Grid>
                            <Grid item className={`${classes.applicationListGridValue}`} >
                                {!refresh_token.infinite_token_lifetime ? 'Enabled' : 'Disabled'}
                            </Grid>
                        </Grid>
                        <Grid item className={`${classes.applicationListSectionHeadingColumn}`} >
                        </Grid>
                        <Grid item className={`${classes.applicationListSectionPropertiesColumn}`} >
                            <Grid item className={`${classes.applicationListGridProperty}`} >
                                Absolute Lifetime
                            </Grid>
                            <Grid item className={`${classes.applicationListGridValue}`} >
                                {`${refresh_token.token_lifetime || 2592000} seconds`}
                            </Grid>
                        </Grid>
                        <Grid item className={`${classes.applicationListSectionHeadingColumn}`} >
                        </Grid>
                        <Grid item className={`${classes.applicationListSectionPropertiesColumn}`} >
                            <Grid item className={`${classes.applicationListGridProperty}`} >
                                Inactivity Expiration
                            </Grid>
                            <Grid item className={`${classes.applicationListGridValue}`} >
                                {!refresh_token.infinite_idle_token_lifetime ? 'Enabled' : 'Disabled'}
                            </Grid>
                        </Grid>
                        <Grid item className={`${classes.applicationListSectionHeadingColumn}`} >
                        </Grid>
                        <Grid item className={`${classes.applicationListSectionPropertiesColumn}`} >
                            <Grid item className={`${classes.applicationListGridProperty}`} >
                                Inactivity Lifetime
                            </Grid>
                            <Grid item className={`${classes.applicationListGridValue}`} >
                                {`${refresh_token.idle_token_lifetime || 1296000} seconds`}
                            </Grid>
                        </Grid>
                    </Grid>
                </React.Fragment>
            }
            <hr className={classes.applicationListSectionDivider}></hr>
        </React.Fragment>
    )
}
export const RenderConnections: React.FC<SubSectionProps> = ({ classes, application: { connections } }) => {
    if (!connections) {
        return null;
    }

    return (
        <React.Fragment>
            <Grid container className={`${classes.applicationListSectionStart}`}>
                <Grid item className={`${classes.applicationListSectionHeadingColumn}`} >
                    Connections
                </Grid>
                <Grid item className={`${classes.applicationListSectionPropertiesColumn}`} >
                    <Grid item className={`${classes.applicationListGridProperty}`} >
                        Enabled connections
                    </Grid>
                    <Grid item className={`${classes.applicationListGridValue}`} >
                        {listItems(connections)}
                    </Grid>
                </Grid>
            </Grid>
            <hr className={classes.applicationListSectionDivider}></hr>
        </React.Fragment>
    )
}

const getGrantType = (grant_type: string) => {
    switch (grant_type) {
        case 'authorization_code':
            return 'Authorization Code'
        case 'implicit':
            return 'Implicit'
        case 'refresh_token':
            return 'Refresh Token'
        case 'client_credentials':
            return 'Client Credentials'
        case 'password':
            return 'Password'
        default:
            return grant_type;
    }

}

export const RenderGrantTypes: React.FC<SubSectionProps> = ({ classes, application: { grant_types } }) => {
    if (!grant_types) {
        return null;
    }

    return (
        <React.Fragment>
            <Grid container className={`${classes.applicationListSectionStart}`}>
                <Grid item className={`${classes.applicationListSectionHeadingColumn}`} >
                    Grant Types (Advanced Settings)
                </Grid>
                <Grid item className={`${classes.applicationListSectionPropertiesColumn}`} >
                    <Grid item className={`${classes.applicationListGridProperty}`} >
                        Grant Types
                    </Grid>
                    <Grid item className={`${classes.applicationListGridValue}`} >
                        {grant_types.map((type, key) => (
                            <div key={key}>{getGrantType(type)}</div>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
            <hr className={classes.applicationListSectionDivider}></hr>
        </React.Fragment>
    )
}

export const RenderEndpoints: React.FC<SubSectionProps> = ({ classes, application }) => {
    const showClientAppSpecificInfo = application.app_type !== AppType.non_interactive;

    return (
        <React.Fragment>
            <Grid container className={`${classes.applicationListSectionStart}`}>
                <Grid item className={`${classes.applicationListSectionHeadingColumn}`} >
                    OAuth Endpoints (Advanced Settings)
                </Grid>
                <Grid item className={`${classes.applicationListSectionPropertiesColumn}`} >
                    <Grid item className={`${classes.applicationListGridProperty}`} >
                        OAuth Authorization URL
                    </Grid>
                    <Grid item className={`${classes.applicationListGridValue}`} >
                        {`https://${settings.auth0.custom_domain}/authorize`}
                    </Grid>
                </Grid>
                <Grid item className={`${classes.applicationListSectionHeadingColumn}`} >
                </Grid>
                <Grid item className={`${classes.applicationListSectionPropertiesColumn}`} >
                    <Grid item className={`${classes.applicationListGridProperty}`} >
                        Device Authorization URL
                    </Grid>
                    <Grid item className={`${classes.applicationListGridValue}`} >
                        {`https://${settings.auth0.custom_domain}/oauth/device/code`}
                    </Grid>
                </Grid>
                <Grid item className={`${classes.applicationListSectionHeadingColumn}`} >
                </Grid>
                <Grid item className={`${classes.applicationListSectionPropertiesColumn}`} >
                    <Grid item className={`${classes.applicationListGridProperty}`} >
                        OAuth Token URL
                    </Grid>
                    <Grid item className={`${classes.applicationListGridValue}`} >
                        {`https://${settings.auth0.custom_domain}/oauth/token`}
                    </Grid>
                </Grid>
                <Grid item className={`${classes.applicationListSectionHeadingColumn}`} >
                </Grid>
                <Grid item className={`${classes.applicationListSectionPropertiesColumn}`} >
                    <Grid item className={`${classes.applicationListGridProperty}`} >
                        OAuth User Info URL
                    </Grid>
                    <Grid item className={`${classes.applicationListGridValue}`} >
                        {`https://${settings.auth0.custom_domain}/userinfo`}
                    </Grid>
                </Grid>
                <Grid item className={`${classes.applicationListSectionHeadingColumn}`} >
                </Grid>
                <Grid item className={`${classes.applicationListSectionPropertiesColumn}`} >
                    <Grid item className={`${classes.applicationListGridProperty}`} >
                        OpenID Configuration
                    </Grid>
                    <Grid item className={`${classes.applicationListGridValue}`} >
                        {`https://${settings.auth0.custom_domain}/.well-known/openid-configuration`}
                    </Grid>
                </Grid>
                <Grid item className={`${classes.applicationListSectionHeadingColumn}`} >
                </Grid>
                <Grid item className={`${classes.applicationListSectionPropertiesColumn}`} >
                    <Grid item className={`${classes.applicationListGridProperty}`} >
                        JSON Web Key Set
                    </Grid>
                    <Grid item className={`${classes.applicationListGridValue}`} >
                        {`https://${settings.auth0.custom_domain}/.well-known/jwks.json`}
                    </Grid>
                </Grid>
            </Grid>
            <hr className={classes.applicationListSectionDivider}></hr>
            {showClientAppSpecificInfo && (
                <>
                    <Grid container className={`${classes.applicationListSectionStart}`}>
                        <Grid item className={`${classes.applicationListSectionHeadingColumn}`} >
                            SAML Endpoints (Advanced Settings)
                        </Grid>
                        <Grid item className={`${classes.applicationListSectionPropertiesColumn}`} >
                            <Grid item className={`${classes.applicationListGridProperty}`} >
                                SAML Protocol URL
                            </Grid>
                            <Grid item className={`${classes.applicationListGridValue}`} >
                                {`https://${settings.auth0.custom_domain}/samlp/${application.client_id}`}
                            </Grid>
                        </Grid>
                        <Grid item className={`${classes.applicationListSectionHeadingColumn}`} >
                        </Grid>
                        <Grid item className={`${classes.applicationListSectionPropertiesColumn}`} >
                            <Grid item className={`${classes.applicationListGridProperty}`} >
                                SAML Metadata URL
                            </Grid>
                            <Grid item className={`${classes.applicationListGridValue}`} >
                                {`https://${settings.auth0.custom_domain}/samlp/metadata/${application.client_id}`}
                            </Grid>
                        </Grid>
                    </Grid>
                    <hr className={classes.applicationListSectionDivider}></hr>
                    <Grid container className={`${classes.applicationListSectionStart}`}>
                        <Grid item className={`${classes.applicationListSectionHeadingColumn}`} >
                            WS-Federation Endpoints (Advanced Settings)
                        </Grid>
                        <Grid item className={`${classes.applicationListSectionPropertiesColumn}`} >
                            <Grid item className={`${classes.applicationListGridProperty}`} >
                                WsFederation Metadata URL
                            </Grid>
                            <Grid item className={`${classes.applicationListGridValue}`} >
                                {`https://${settings.auth0.custom_domain}/wsfed/${application.client_id}/FederationMetadata/2007-06/FederationMetadata.xml`}
                            </Grid>
                        </Grid>
                        <Grid item className={`${classes.applicationListSectionHeadingColumn}`} >
                        </Grid>
                        <Grid item className={`${classes.applicationListSectionPropertiesColumn}`} >
                            <Grid item className={`${classes.applicationListGridProperty}`} >
                                WsFederation Sign-in URL
                            </Grid>
                            <Grid item className={`${classes.applicationListGridValue}`} >
                                {`https://${settings.auth0.custom_domain}/wsfed/${application.client_id}`}
                            </Grid>
                        </Grid>
                    </Grid>
                    <hr className={classes.applicationListSectionDivider}></hr>
                </>
            )}
        </React.Fragment>
    )
}

export const RenderClientMetaData: React.FC<SubSectionProps> = ({ classes, application: { client_metadata } }) => {
    if (!client_metadata) {
        return (
            <React.Fragment>
                <Grid container className={`${classes.applicationListSectionStart}`}>
                    <Grid item className={`${classes.applicationListSectionHeadingColumn}`} >
                        Client Meta Data
                    </Grid>
                    <Grid item className={`${classes.applicationListSectionPropertiesColumn}`} >
                        <Grid item className={`${classes.applicationListGridValue}`} >
                            No meta data available
                        </Grid>
                    </Grid>
                </Grid>
                <hr className={classes.applicationListSectionDivider}></hr>
            </React.Fragment>
        )
    };

    return (
        <React.Fragment>
            <Grid container className={`${classes.applicationListSectionStart}`}>
                {Object.keys(client_metadata).sort((key1, key2) => key1.localeCompare(key2)).map((key, idx) => (
                    <React.Fragment key={idx}>
                        <Grid item className={`${classes.applicationListSectionHeadingColumn}`} >
                            {idx === 0 && 'Client Meta Data'}
                        </Grid>
                        <Grid item className={`${classes.applicationListSectionPropertiesColumn}`} >
                            <Grid item className={`${classes.applicationListGridProperty}`} >
                                {key}
                            </Grid>
                            <Grid item className={`${classes.applicationListGridValue}`} >
                                {client_metadata[key]}
                            </Grid>
                        </Grid>

                    </React.Fragment>
                ))}
            </Grid>
            <hr className={classes.applicationListSectionDivider}></hr>
        </React.Fragment>
    )
}
