import colors from '@danfoss/webex-ui/dist/styles/_colors.scss';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
	propertiesContainer: {
		display: 'grid',
		gap: 4,
	},
	property: {
		alignItems: 'center',
	},
	delimeter: {
		height: 8,
	},
	code: {
		borderRadius: 4,
		padding: '0 6px',
		backgroundColor: colors.webexuiGrayBg,
		fontSize: 14
	},
}));