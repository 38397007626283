import React from 'react';
import Grid from '@material-ui/core/Grid';
import { SubSectionProps } from '../types';
import { useStyles } from './styles';
import { Typography } from '@material-ui/core';


export const ApiAccessInfo: React.FC<SubSectionProps> = ({ classes: commonClasses, application: { grants } }) => {
    const classes = useStyles();

    if (!grants) {
        return null;
    }

    return (
        <React.Fragment>
            <Grid container className={commonClasses.applicationListSectionStart}>
                <Grid item className={commonClasses.applicationListSectionHeadingColumn}>
                    APIs Access
                </Grid>
                <Grid item className={`${commonClasses.applicationListSectionPropertiesColumn} ${classes.propertiesContainer}`}>
                    {grants.map((g, i) => (
                        <React.Fragment key={g.grant_id}>
                            {i !== 0 && <div className={classes.delimeter} />}
                            <Grid item className={`${commonClasses.applicationListGridProperty}`} >
                                {g.api_name}
                            </Grid>
                            <Grid item container spacing={1} className={`${commonClasses.applicationListGridValue} ${classes.property}`}>
                                <Grid item>
                                    <Typography>
                                        API Identifier:
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.code}>
                                        {g.api_audience}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item container spacing={1} className={`${commonClasses.applicationListGridValue} ${classes.property}`}>
                                <Grid item>
                                    <Typography>
                                        Granted Scopes:
                                    </Typography>
                                </Grid>
                                {g.granted_scopes.length > 0 ? g.granted_scopes.map(s => (
                                    <Grid key={s} item>
                                        <Typography className={classes.code}>
                                            {s}
                                        </Typography>
                                    </Grid>
                                )) : (
                                    <Grid item>
                                        <Typography>
                                            -
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                        </React.Fragment>
                    ))}
                </Grid>
            </Grid>
            <hr className={commonClasses.applicationListSectionDivider} />
        </React.Fragment>
    );
}