import React from "react";
// @ts-ignore
import ReactExport from "react-data-export";
import Button from '@material-ui/core/Button';
import { DownloadProps } from './types';
import { useStyles } from './styles';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


const Download: React.FC<DownloadProps> = ({ className, label = 'Export texts', getDataItems, disabled, fileName }) => {
    const dataItems = getDataItems();
    const classes = useStyles();

    const downloadButton = (
        <Button
            type="button"
            variant="outlined"
            color="default"
            className={`${className} ${classes.button}`}
            disabled={disabled}
        >
            {label}
        </Button>
    )

    return disabled ? (
        downloadButton
    ) : (
        <ExcelFile element={downloadButton} filename={fileName}>
            {
                dataItems.map((dataItem: any, index: number) => {
                    return (<ExcelSheet dataSet={[dataItem.dataSet]} name={dataItem.sheetName} key={index} />)
                })
            }
        </ExcelFile>
    );
}

export default Download