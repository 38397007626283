import React, { useState } from 'react';
import { useStyles } from './AppRoles/styles';
import Button from '@material-ui/core/Button';
import Download from '../Download';
import { ApplicationErrors, ApplicationWarnings, ImportProgress, XlsxEncryptionError, XlsxParsingError } from '../ImportPopup/types';
import * as XLSX from 'xlsx';
import ImportErrorsPopup from '../ImportPopup/ImportErrorsPopup';
import ImportWarningsPopup from '../ImportPopup/ImportWarningsPopup';
import ImportConfirmPopup from '../ImportPopup/ImportConfirmPopup';


export interface ImportManagerProps<T> {
    fileName: string;
    columns: string[];
    dataToExport: string[][];
    getItemName: (item: T) => string;
    deserializeItem: (row: string[]) => T | undefined;
    validateImportedData?: (data: T[]) => { errors: ApplicationErrors[], warnings: ApplicationWarnings[] };
    handleImport: (data: T[]) => void;
}

export const ImportManager = <T,>({
    fileName,
    columns,
    dataToExport,
    getItemName,
    deserializeItem,
    validateImportedData,
    handleImport,
}: ImportManagerProps<T>) => {
    const classes = useStyles();
    const [isSaving, setIsSaving] = useState(false);
    const [importTypeProgress, setImportTypeProgress] = useState(ImportProgress.NotStarted);
    const [importedData, setImportedData] = useState<T[]>([]);
    const [importedDataErrors, setImportedDataErrors] = useState({ errors: [], generalError: '', warnings: [] } as { errors: ApplicationErrors[], generalError?: string, warnings: ApplicationWarnings[] });

    const getDataItems = () => {
        if (dataToExport.length === 0) {
            return [];
        }

        return [
            {
                sheetName: fileName,
                dataSet: {
                    columns: columns.map(c => ({ title: c })),
                    data: dataToExport.map((row) => row.map(c => ({ value: c })))
                }
            }
        ];
    };

    const handleImportClick = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsSaving(true);
        setImportTypeProgress(ImportProgress.ConfirmImport);

        const file = e.target.files?.[0];
        if (!file) return;

        const reader = new FileReader();

        reader.onload = function (event) {
            const arrayBuffer = event.target?.result as ArrayBuffer;

            try {
                const items: T[] = [];

                const readData = XLSX.read(new Uint8Array(arrayBuffer), { type: 'array' });
                readData.SheetNames.forEach((sheet) => {
                    const sheetData = readData.Sheets[sheet];
                    const sheetDataAsJson = XLSX.utils.sheet_to_json(sheetData, { header: 1 });

                    const userRolesRows = sheetDataAsJson.slice(1) as string[][];

                    userRolesRows.forEach(row => {
                        const item = deserializeItem(row);
                        if (item) {
                            items.push(item);
                        }
                    });
                });

                const { errors, warnings } = validateImportedData?.(items) ?? { errors: [], warnings: [] };

                if (errors.length > 0) {
                    console.log('Errors', errors);
                    setImportTypeProgress(ImportProgress.HandleErrors);
                } else if (warnings.length > 0) {
                    console.log('Warnings', warnings);
                    setImportTypeProgress(ImportProgress.HandleWarnings);
                } else {
                    setImportTypeProgress(ImportProgress.ConfirmImport);
                }

                setImportedData(items);
                setImportedDataErrors({ errors, warnings });
            } catch (error: unknown) {
                setImportTypeProgress(ImportProgress.HandleErrors);

                const generalError = (error as Error).message.includes('Encrypted file missing /EncryptionInfo')
                    ? XlsxEncryptionError
                    : XlsxParsingError.replace('{error-message}', (error as Error).message);
                setImportedDataErrors({
                    errors: [],
                    generalError,
                    warnings: [],
                });
            }
        };

        e.target.value = '';
        reader.readAsArrayBuffer(file);
    };

    const cancelImport = () => {
        setImportedDataErrors({ errors: [], warnings: [] });
        setImportTypeProgress(ImportProgress.NotStarted);
        setImportedData([]);
        setIsSaving(false);
    }

    const continueImport = async () => {
        handleImport(importedData);

        setIsSaving(false);
        setImportTypeProgress(ImportProgress.NotStarted);
    }

    return (
        <>
            <ImportErrorsPopup importTypeProgress={importTypeProgress} errors={importedDataErrors.errors} generalError={importedDataErrors.generalError} onCancelImport={cancelImport} disableScroll />
            <ImportWarningsPopup importTypeProgress={importTypeProgress} warnings={importedDataErrors.warnings} onCancelImport={cancelImport} onContinueImport={continueImport} importButtonTypeText="Import users" showOtherFilesButton={false} disableScroll />
            <ImportConfirmPopup importTypeProgress={importTypeProgress} importItemNames={importedData.map(getItemName)} importTypeText="users" importButtonTypeText="users" onCancelImport={cancelImport} onPerformImport={continueImport} disableScroll />
            <Download
                className={classes.editGridButton}
                label='Export'
                getDataItems={getDataItems}
                disabled={dataToExport.length === 0}
                fileName={fileName}
            />
            <Button
                component="label"
                variant="outlined"
                color="default"
                className={classes.editGridButton}
                disabled={isSaving}
            >
                Import<input type="file" hidden onChange={(e) => handleImportClick(e)} accept=".xlsx" />
            </Button>
        </>
    );
};
