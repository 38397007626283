import fetchApiWrapper from './fetchAPIWrapper';
import settings from '../Config/settings';

export interface UserActivityHistory {
    emails: Message[];
    bounces: EmailDeliveryError[];
    blocks: EmailDeliveryError[];
}

export interface Message {
    id: string;
    subject: string;
    status: string;
    opens_count: number;
    clicks_count: number;
    created_at: string;
}

export interface EmailDeliveryError {
    status: string;
    reason: string;
    created_at: string;
}

export interface UserAuth0LogsResponse {
    auth0_logs: UserAuth0Log[];
}

export interface UserAuth0Log {
    id: string;
    type: string;
    type_code: string;
    description: string | undefined;
    client_name: string | undefined;
    ip: string;
    date: string;
}

type PaginationParams = {
    page: number;
    per_page: number;
}

const user_id_type = 'user_id';
const defaultQueryParams = new URLSearchParams({ user_id_type });


export const getUserActivityHistory = (accessToken: string, auth0_user_id: string): Promise<UserActivityHistory> => {
    const requestHeaders = new Headers({
        'Authorization': `BEARER ${accessToken}`
    });

    return fetchApiWrapper(`${settings.myDanfossApi.endpoint}/user-activity-history/${auth0_user_id}?${defaultQueryParams}`, {
        method: 'GET',
        headers: requestHeaders,
    });
}

export const getUserAuthActivityHistory = (accessToken: string, auth0_user_id: string, { page, per_page }: PaginationParams): Promise<UserAuth0LogsResponse> => {
    const requestHeaders = new Headers({
        'Authorization': `BEARER ${accessToken}`
    });

    const queryParams = new URLSearchParams({
        page: page.toString(),
        per_page: per_page.toString(),
        user_id_type,
    });

    return fetchApiWrapper(`${settings.myDanfossApi.endpoint}/user-activity-history/${auth0_user_id}/auth0-logs?${queryParams}`, {
        method: 'GET',
        headers: requestHeaders,
    });
}

export const deleteUserBounce = (accessToken: string, auth0_user_id: string): Promise<void> => {
    const requestHeaders = new Headers({
        'Authorization': `BEARER ${accessToken}`
    });

    return fetchApiWrapper(`${settings.myDanfossApi.endpoint}/user-activity-history/${auth0_user_id}/bounce?${defaultQueryParams}`, {
        method: 'DELETE',
        headers: requestHeaders,
    });
}